import React from 'react';
import { ListStyled, ListItem } from './styles';
import { COLORS } from '../../helper';
import { useSurvey } from '../../useSurvey';

const Indexes = () => {
  const [{ selectedSurveys }] = useSurvey();
  const MAX_LENGTH = 7;
  const surveyIndexes = [];

  for (let i = 0; i <= MAX_LENGTH; i++) {
    surveyIndexes[i] = { color: null, index: i + 1 };
  }

  return (
    <ListStyled>
      {surveyIndexes.map((value, index) => (
        <ListItem bgColor={COLORS[selectedSurveys[index]?.group]} key={value.index}>
          {value.index}
        </ListItem>
      ))}
    </ListStyled>
  );
};

export default Indexes;
