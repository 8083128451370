import React from 'react';
import { PrimaryButton } from '../../../globalStyles/utils';

const Footer = ({ disabled }) => {
  return (
    <PrimaryButton type="submit" fullWidth disabled={disabled}>
      Submit
    </PrimaryButton>
  );
};

export default Footer;
