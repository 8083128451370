import styled from 'styled-components';
import { theme } from '../../../../globalStyles/theme';

export const Wrapper = styled.div`
  flex-grow: 1;
`;

export const SelectedListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 ${theme.spacingSmall};
`;

export const ListItemStyled = styled.li`
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ borderColor }) => borderColor};
  background-image: linear-gradient(180deg, #302e30 0%, #2b2b2c 100%);
  border-radius: 8px;
  padding: ${theme.spacingSmall};
  margin-bottom: ${theme.spacingSmall};
  & > * {
    display: flex;
    align-items: center;
  }
  .icon-edit {
    flex-shrink: 0;
    font-size: ${theme.size};
    cursor: pointer;
  }

  .icon-info {
    flex-shrink: 0;
    margin-right: ${theme.spacing};
    font-size: ${theme.sizeMedium};
  }
  div {
    flex-grow: 1;
    font-weight: bold;
  }
`;
