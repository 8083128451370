import styled from 'styled-components';
import { theme } from '../../globalStyles/theme';
import { Radio } from 'antd';

export const WrapperStyled = styled.div`
  margin: ${theme.spacingLarge} 0;
`;

export const RadioGroupStyled = styled(Radio.Group)`
  &&& {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    & > * {
      display: flex;
      flex: 0 0 auto;
    }
    & > *:first-child,
    & > *:last-child {
      flex: 1 0 0;
    }
    margin-bottom: ${theme.spacing};
  }
`;

export const RadioButtonStyled = styled(Radio.Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 40px;
  border: 1px dashed ${theme.colorWhite} !important;
  border-radius: ${theme.borderRadius};
  background-image: linear-gradient(180deg, #302e30 0%, #2b2b2c 100%);
  color: ${theme.colorWhite};
  font-family: ${theme.fontNunito};
  font-size: ${theme.sizeXsSmall};
  line-height: 18px;
  font-weight: bold;
  box-shadow: none !important;

  @media (min-width: ${theme.breakpoints.xs}px) {
    font-size: ${theme.sizeSmall};
  }

  &:hover,
  &:focus {
    background-color: unset;
    color: ${theme.colorWhite};
  }

  &.ant-radio-button-wrapper-checked {
    color: ${theme.colorWhite};
    border-color: ${theme.backgroundPurple} !important;
    background-color: ${theme.backgroundPurple};

    .ant-radio-button-checked {
      color: ${theme.colorWhite};
      background-color: ${theme.backgroundPurple};
      &:hover,
      &:focus {
        color: ${theme.colorWhite};
      }
    }
    &:hover,
    &:focus {
      color: ${theme.colorWhite};
    }
  }

  &:before {
    display: none !important;
  }
`;

export const RadioVsButtonStyled = styled(Radio.Button)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-color: ${theme.backgroundPurple};
    color: ${theme.colorWhite};
    font-family: ${theme.fontNunito};
    font-size: ${theme.sizeXsSmall};
    font-weight: bold;
    line-height: ${theme.lineHeight};
    border: none;
    cursor: default;
    margin: 0 ${theme.spacingSmall};

    &:before {
      display: none !important;
    }

    @media (min-width: ${theme.breakpoints.xs}px) {
      height: 40px;
      width: 40px;
    }
  }
`;
