import React from 'react';
import { Container } from '../../../globalStyles/utils';
import { BodyStyled } from './styles';

const Body = ({ hasFooter, children }) => (
  <BodyStyled hasFooter={hasFooter}>
    <Container>{children}</Container>
  </BodyStyled>
);

export default Body;
