import React, { useEffect, useState } from 'react';
import { WrapperStyled } from './styles';

const CompletedRound = ({ show, roundNumber }) => {
  if (!show) return null;

  return (
    <WrapperStyled>
      <div>{roundNumber === 7 ? 'We have a Winner!' : `Round ${roundNumber} completed!`}</div>
    </WrapperStyled>
  );
};
export default CompletedRound;
