import React from 'react';
import { PrimaryButton } from '../../../globalStyles/utils';
import { useSurvey } from '../useSurvey';
import { submitSelections } from '../../../api';

const Footer = ({ authToken, surveyId }) => {
  const [{ disabledOrder, selectedSurveys }, { setDisabledOrder }] = useSurvey();

  return (
    <PrimaryButton
      disabled={disabledOrder}
      fullWidth
      onClick={() => {
        const body = { survey: { selections: selectedSurveys.map(selectedSurvey => selectedSurvey.name) } };
        setDisabledOrder(true);
        submitSelections(authToken, body, surveyId);
      }}
    >
      Find What You Want Most
    </PrimaryButton>
  );
};

export default Footer;
