import React from 'react';
import { ListStyled } from './styles';

const StepsList = () => {
  const steps = [
    'Pick Your Top 8 Things',
    'Choose What You Want Most',
    'Get Your Results',
  ];

  return (
    <ListStyled>
      {steps.map((step, index) => {
        const stepIndex = index++;
        return (
          <li key={index}>
            <span className={`label label-${stepIndex}`}>STEP {stepIndex + 1}</span>
            {step}
          </li>
        );
      })}
    </ListStyled>
  );
};

export default StepsList;
