import React, { useState } from 'react';
import {
  WrapperStyled,
  MenuStyled,
  ItemStyled,
  SubMenuStyled,
  SubMenuSecondaryStyled,
  ItemSecondaryStyled,
} from './styles';
import { IconTriangle } from '../../../../../icons';
import Hint from './Hint';
import AddCustomForm from './AddCustomForm';
import { useSurvey } from '../../../useSurvey';
import { ADD_CUSTOM_KEY, NONE_SURVEY_TYPE } from '../../../helper';

const SurveyPoints = () => {
  const [{ data, selectedSurveys, surveyType }, { addSurvey, editSurvey, setSurveyType }] = useSurvey();

  const [openKeys, setOpenKeys] = useState([]);

  const surveys = Object.entries(data);
  const rootSubmenuKeys = Object.values(data);

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onHandleSurvey = (data, item, title) => {
    const strategy = {
      add: addSurvey,
      edit: editSurvey,
    };

    const newSurvey = {
      id: item.name + title,
      description: item.description,
      name: item.name,
      group: title,
    };

    strategy?.[surveyType](data, newSurvey);

    setSurveyType(NONE_SURVEY_TYPE);
  };

  return (
    <WrapperStyled>
      <MenuStyled mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
        {surveys.map(survey => {
          const title = survey[0];
          const list = survey[1];

          return (
            <SubMenuStyled
              key={title}
              color={title}
              title={
                <>
                  <span className="text">{title}</span>
                  <span className="icon">
                    <IconTriangle />
                  </span>
                </>
              }
            >
              {list.map(item => (
                <ItemStyled
                  disabled={item.action}
                  onClick={() => onHandleSurvey(data, item, title)}
                  color={title}
                  key={title + item.name}
                >
                  <span className="text">{item.name.replace('/', ' / ')}</span>
                </ItemStyled>
              ))}
            </SubMenuStyled>
          );
        })}
      </MenuStyled>
      <MenuStyled mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
        <SubMenuSecondaryStyled
          key={ADD_CUSTOM_KEY}
          color={ADD_CUSTOM_KEY}
          title={
            <>
              <span className="text">Add Custom</span>
              <span className="icon">
                <IconTriangle />
              </span>
            </>
          }
        >
          <ItemSecondaryStyled key="AddCustomItem">
            <AddCustomForm setOpenKeys={setOpenKeys} />
          </ItemSecondaryStyled>
        </SubMenuSecondaryStyled>
      </MenuStyled>

      {!!openKeys.length || !!selectedSurveys.length ? null : <Hint />}
    </WrapperStyled>
  );
};

export default SurveyPoints;
