import styled from 'styled-components';
import { theme } from '../../../../globalStyles/theme';

export const ListStyled = styled.ul`
  flex-shrink: 0;
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#d2d6dc')};
  color: ${({ bgColor }) => (bgColor ? theme.colorWhite : '#4b5563')};
  border: 2px solid #d2d6dc;
  font-family: ${theme.fontBeausite};
  font-size: ${theme.sizeXsLarge};
  font-weight: bold;
  letter-spacing: 0;
  line-height: ${theme.lineHeightXsLarge};
  margin-right: ${theme.spacingSmall};
  border-radius: 50%;
  & + li {
    margin-top: ${theme.spacingSmall};
  }
`;
