import React, { useContext, useState, useCallback, useEffect } from 'react';
import { NONE_SURVEY_TYPE, EDIT_SURVEY_TYPE, MAX_SURVEYS_LENGTH, ADD_CUSTOM_KEY } from './helper';

const SurveysContext = React.createContext(null);

export const SurveysProvider = ({ initialState, children }) => {
  const [surveyState, setSurveyState] = useState({ ...initialState });
  const [disabledOrder, setDisabledOrder] = useState(true);
  const [surveyType, setSurveyType] = useState(NONE_SURVEY_TYPE);
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [editableSurveyId, setEditableSurveyId] = useState(null);

  useEffect(() => {
    if (selectedSurveys.length === MAX_SURVEYS_LENGTH && surveyType === NONE_SURVEY_TYPE) {
      setDisabledOrder(false);
    } else {
      setDisabledOrder(true);
    }
  }, [selectedSurveys, surveyType]);

  const updateSurveyAction = useCallback(
    (surveyState, key, value, action) => {
      if (key !== ADD_CUSTOM_KEY) {
        const updateGroup = surveyState[key].map(item =>
          item.name.toLowerCase() === value.toLowerCase() ? { ...item, action } : item,
        );

        setSurveyState({ ...surveyState, [key]: updateGroup });
      }
    },
    [selectedSurveys, setSelectedSurveys],
  );

  const addSurvey = useCallback(
    (surveyState, survey) => {
      if (selectedSurveys.length !== MAX_SURVEYS_LENGTH) {
        setSelectedSurveys([...selectedSurveys, survey]);
        updateSurveyAction(surveyState, survey.group, survey.name, true);
      }
    },
    [selectedSurveys, setSelectedSurveys],
  );

  const editSurvey = useCallback(
    (surveyState, survey) => {
      const editedSurvey = selectedSurveys.map(selectedSurvey =>
        selectedSurvey.id === editableSurveyId ? survey : selectedSurvey,
      );

      updateSurveyAction(surveyState, survey.group, survey.name, true);
      setSelectedSurveys(editedSurvey);
      setEditableSurveyId(null);
    },

    [selectedSurveys, setSelectedSurveys, editableSurveyId],
  );

  const sliceData = useCallback(
    data => {
      const end = selectedSurveys.findIndex(selectedSurvey => selectedSurvey.id === editableSurveyId);

      if (surveyType === EDIT_SURVEY_TYPE) {
        return data.slice(_, end);
      }

      return data;
    },
    [editableSurveyId, surveyType],
  );

  const state = {
    data: surveyState,
    selectedSurveys,
    editableSurveyId,
    surveyType,
    disabledOrder,
  };

  const methods = {
    editSurvey,
    addSurvey,
    setSurveyType,
    setDisabledOrder,
    setEditableSurveyId,
    updateSurveyAction,
    sliceData,
  };

  const value = [state, methods];

  return <SurveysContext.Provider value={value}>{children}</SurveysContext.Provider>;
};

export const useSurvey = () => useContext(SurveysContext);
