import React from 'react';
import { WrapperStyled, ListStyled, TitleStyled } from './styles';

const ListHint = () => (
  <WrapperStyled>
    <TitleStyled>The keys to picking what you want most.</TitleStyled>
    <ListStyled>
      <li>Ask yourself if I did not have one would I still want the other?</li>
      <li>Is this something I can’t live without?</li>
      <li>Trust your instincts.</li>
      <li>There's an elimination bracket so have fun.</li>
      <li>If you are struggling, write down definitions.</li>
    </ListStyled>
  </WrapperStyled>
);
export default ListHint;
