import styled from 'styled-components';
import { theme } from '../../../globalStyles/theme';

export const FooterStyled = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: ${theme.footerHeight};
  padding: ${theme.spacing} 0;
  background-color: #161e2e;
  display: flex;
  align-items: center;
`;

export const WrapperStyled = styled.div`
  width: 100%;
  position: relative;
  z-index: 10;
`;
