import styled from 'styled-components';
import { theme } from '../../../globalStyles/theme';

export const WrapperStyled = styled.div`
  margin-top: ${theme.spacingXXLarge};
  height: 130px;
`;

export const TitleStyled = styled.div`
  text-align: center;
  color: ${({ color }) => (color ? color : theme.colorWhite)};
  font-family: ${theme.fontFractul};
  font-size: ${theme.sizeLarge};
  line-height: ${theme.lineHeightXLarge};
  font-weight: 500;
`;

export const RoundsStyled = styled.div`
  margin-top: ${theme.spacing};
  display: flex;
  justify-content: space-around;
`;

export const RoundStyled = styled.div`
  color: ${({ active, color }) => (active ? color : theme.colorWhite)};
  font-family: ${theme.fontNunito};
  font-size: ${theme.size};
  line-height: ${theme.lineHeight};
`;
