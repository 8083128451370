import React from 'react';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import { Wrapper } from './styles';

const Layout = ({ body, footer = null }) => {
  const hasFooter = !!footer;
  return (
    <Wrapper>
      <Header />
      {body && <Body hasFooter={hasFooter}>{body}</Body>}
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
};

export default Layout;
