export const theme = {
  colorWhite: '#ffffff',
  colorOrange: '#FF8A4C',
  colorOrangeDarken: '#FF5A1F',
  colorYellow: '#E3A008',
  colorYellowLighten: '#FACA15',
  colorYellowDarken: '#C27803',
  colorGreen: '#31C48D',
  colorGreenDarken: '#046C4E',
  colorTeal: '#047481',
  colorTealDarken: '#036672',
  colorBlue: '#1C64F2',
  colorBlueDarken: '#1A56DB',
  colorPurple: '#7E3AF2',
  colorPurpleDarken: '#6C2BD9',
  colorBunting: '#27303F',
  colorBuntingLighten: '#364152',
  colorMelrose: '#ac94fa',

  borderRadius: '2px',
  borderRadiusLarge: '8px',
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',

  background: '#19191A',
  backgroundPurple: '#4A1D96',
  backgroundInverse: '#ffffff',

  backgroundButton: '#0e9f6e',
  backgroundButtonHover: '#0c885e', // darken 5%

  gradientPurple: 'linear-gradient(180deg, #4a1d96 0%, #19191a 100%)',

  borderColorGray: '#FBFDFE',

  fontBeausite: '"Beausite Classic"',
  fontBeausiteRegular: '"Beausite Classic Regular"',
  fontBeausiteClear: '"Beausite Classic Clear"',
  fontFractul: 'Fractul',
  fontFractulMedium: 'FractulMedium',
  fontFractulAlt: '"Fractul Alt"',
  fontFractulHairline: '"Fractul Hairline"',
  fontFractulAltHairline: '"Fractul Alt Hairline"',
  fontNunito: '"Nunito Sans"',

  spacingXsSmall: '4px',
  spacingSmall: '8px',
  spacing: '16px',
  spacingMedium: '24px',
  spacingLarge: '32px',
  spacingXLarge: '40px',
  spacingXXLarge: '48px',

  sizeXsSmall: '12px',
  sizeSmall: '14px',
  size: '16px',
  sizeXsMedium: '18px',
  sizeMedium: '20px',
  sizeXsLarge: '24px',
  sizeLarge: '32px',
  sizeXLarge: '40px',
  sizeXlLarge: '48px',
  sizeXxlLarge: '60px',

  lineHeightXsSmall: '12px',
  lineHeightSmall: '14px',
  lineHeight: '20px',
  lineHeightXsMedium: '22px',
  lineHeightMedium: '26px',
  lineHeightXsLarge: '28px',
  lineHeightLarge: '36px',
  lineHeightXLarge: '48px',
  lineHeightXlLarge: '60px',

  headerHeight: '80px',
  footerHeight: '100px',

  breakpoints: {
    xs: 360,
  },
};
