import styled from 'styled-components';
import { theme } from '../../../globalStyles/theme';

export const WrapperStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(000, 000, 000, 0.45);
  z-index: 3000;

  div {
    max-width: 310px;
    color: ${theme.colorYellowLighten};
    font-family: ${theme.fontFractul};
    font-size: 56px;
    font-weight: 500;
    line-height: 64px;
    text-align: center;
    text-shadow: 1px 1px 0 0 #ffffff;
  }
`;
