export const options = (method, authToken, body) => ({
  method: method,
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-Token': authToken,
  },
  body: JSON.stringify(body),
});

export const fetchSuccess = (url, options) =>
  fetch(url, options).then(response => {
    if (response.status > 204) {
      throw new Error('Not success');
    }
    return response.json();
  });

export const letsStarted = authToken => {
  fetchSuccess('/surveys', options('POST', authToken)).then(data => {
    window.location.href = data.redirect_path;
  });
};

export const submitSelections = (authToken, selections, id) => {
  fetchSuccess(`/surveys/${id}`, options('PUT', authToken, selections)).then(data => {
    window.location.href = data.redirect_path;
  });
};

export const readyToPlayoff = (authToken, id) => {
  fetchSuccess(`/surveys/${id}`, options('PUT', authToken)).then(data => {
    window.location.href = data.redirect_path;
  });
};

export const submitRound = (authToken, id, body) => {
  return fetchSuccess(`/surveys/${id}`, options('PUT', authToken, body));
};

export const getLinkLists = (authToken, body) => {
  fetchSuccess('/results', options('PUT', authToken, body)).then(data => {
    window.location.href = data.redirect_path;
  });
};

export const shareMail = (authToken, body) => {
  return fetchSuccess('/share_mail', options('POST', authToken, body));
};
