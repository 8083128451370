import React from 'react';
import { WrapperStyled, RadioVsButtonStyled, RadioGroupStyled, RadioButtonStyled } from './styles';
import { chunk } from 'lodash';

const VersusList = ({ attributes, setFieldValue, values }) => {
  const onChange = (e, key) => {
    const value = e.target.value;
    setFieldValue(key, value);
  };

  return (
    <WrapperStyled>
      {chunk(attributes, 2).map((roundAttribute, index) => (
        <RadioGroupStyled onChange={e => onChange(e, `winner-${index}`)} key={index} value={values[`winner-${index}`]}>
          <RadioButtonStyled value={roundAttribute[0]}>{roundAttribute[0].replace('/', ' / ')}</RadioButtonStyled>
          <RadioVsButtonStyled disabled>VS</RadioVsButtonStyled>
          <RadioButtonStyled value={roundAttribute[1]}>{roundAttribute[1].replace('/', ' / ')}</RadioButtonStyled>
        </RadioGroupStyled>
      ))}
    </WrapperStyled>
  );
};

export default VersusList;
