import React from 'react';
import { ListStyled, ListItem } from './styles';

const ListWinners = ({ winners }) => {
  return (
    <ListStyled>
      {winners.map((winner, index) => (
        <ListItem key={winner}>
          <div className="index">{index + 1}</div>
          <div className="label">{winner}</div>
        </ListItem>
      ))}
    </ListStyled>
  );
};

export default ListWinners;
