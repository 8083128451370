import React, { useState } from 'react';
import Layout from '../../components/Layout';
import Heading from '../../components/Heading';
import { isEmpty } from 'lodash';
import { Formik } from 'formik';
import { FormStyled, FormFieldStyled, InputStyled } from './styles';
import Footer from '../InRounds/Footer';
import { getLinkLists } from '../../api';

const Results = ({ authToken, winner }) => {
  const headingContent = {
    title: 'Your #1 is ...',
    winnerAttribute: winner,
    descriptions: ['To see the ranking of your Top 4 & your Bottom 4, enter your name & email address below.'],
    descriptionSize: 'large',
    descriptionAlign: 'center',
  };
  const [disabled, setDisabled] = useState(true);

  const validate = values => {
    let errors = {};

    if (!values.name.trim()) {
      errors.name = 'Name cannot be blank';
    }

    if (!values.email) {
      errors.email = 'Enter your email address';
    } else if (!/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    setDisabled(!isEmpty(errors));

    return errors;
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
      }}
      validate={validate}
      onSubmit={values => {
        const body = {
          result: {
            name: values.name,
            email_address: values.email,
          },
        };
        getLinkLists(authToken, body);
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <FormStyled onSubmit={handleSubmit} className="ant-form ant-form-vertical">
          <Layout
            body={
              <>
                <Heading content={headingContent} />

                <FormFieldStyled label="Name">
                  <InputStyled
                    onChange={e => {
                      const value = e.target.value;
                      setFieldValue('name', value);
                    }}
                    value={values.name}
                  />
                </FormFieldStyled>

                <FormFieldStyled label="Email">
                  <InputStyled
                    onChange={e => {
                      const value = e.target.value;
                      setFieldValue('email', value);
                    }}
                    value={values.email}
                  />
                </FormFieldStyled>
              </>
            }
            footer={<Footer disabled={disabled} />}
          />
        </FormStyled>
      )}
    </Formik>
  );
};

export default Results;
