import React from 'react';
import {
  WrapperStyled,
  RadioVsButtonStyled,
  RadioGroupStyled,
  RadioButtonStyled,
} from '../../../components/VersusList/styles';

const VersusList = ({ setSelectedRadio }) => {
  const onChange = e => {
    const value = e.target.value;
    setSelectedRadio(value);
  };

  return (
    <WrapperStyled>
      <RadioGroupStyled onChange={onChange}>
        <RadioButtonStyled value="creativity">Flexibility</RadioButtonStyled>
        <RadioVsButtonStyled disabled>VS</RadioVsButtonStyled>
        <RadioButtonStyled value="safeSpace">Money</RadioButtonStyled>
      </RadioGroupStyled>
    </WrapperStyled>
  );
};

export default VersusList;
