import React from 'react';
import { IconLongArrowLeft } from '../../../../../../icons';
import { DescriptionStyled } from '../../../../../../globalStyles/utils';
import { FigureOrange, FigureYellow, FigureBunting, Top, Bottom } from './styles';

const Hint = () => {
  return (
    <>
      <Top>
        <FigureOrange>
          <IconLongArrowLeft theme="orange" />
        </FigureOrange>
        <FigureYellow>
          <IconLongArrowLeft theme="yellow" />
        </FigureYellow>

        <DescriptionStyled>We have broken terms down by category.</DescriptionStyled>
        <DescriptionStyled>Selecting a category will show possible things you may want form work.</DescriptionStyled>
        <DescriptionStyled>Selecting a term will add it to your list.</DescriptionStyled>
      </Top>
      <Bottom>
        <FigureBunting>
          <IconLongArrowLeft theme="bunting" />
        </FigureBunting>

        <DescriptionStyled>Don’t see what you want? Add custom things here.</DescriptionStyled>
      </Bottom>
    </>
  );
};

export default Hint;
