import styled from 'styled-components';
import { theme } from '../../../globalStyles/theme';

export const WrapperStyled = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: ${theme.headerHeight};
  padding: ${theme.spacingMedium} 0 ${theme.spacing};
  background-color: ${theme.backgroundPurple};
`;

export const ContentStyled = styled.div`
  width: 200px;
  height: 40px;
  margin: 0 auto;
`;
