/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactOnRails from 'react-on-rails';
import Home from '../bundles/pages/Home';
import Surveys from '../bundles/pages/Surveys';
import Tutorial from '../bundles/pages/Tutorial';
import InRounds from '../bundles/pages/InRounds';
import Results from '../bundles/pages/Results';
import Completed from '../bundles/pages/Completed';

// This is how react_on_rails can see the Messenger in the browser.
ReactOnRails.register({
  Home,
  Surveys,
  Tutorial,
  InRounds,
  Results,
  Completed,
});
