import React from 'react';
import Layout from '../../components/Layout';
import Heading from '../../components/Heading';
import Footer from './Footer';
import PickList from './PickList';
import { SurveysProvider } from './useSurvey';

const Surveys = ({ careerAttributes, authToken, surveyId }) => {
  const headingContent = {
    title: 'Pick 8',
    descriptions: ['In no particular order what are the 8 things you want from work?'],
  };

  return (
    <SurveysProvider initialState={careerAttributes}>
      <Layout
        body={
          <>
            <Heading content={headingContent} />
            <PickList />
          </>
        }
        footer={<Footer authToken={authToken} surveyId={surveyId} />}
      />
    </SurveysProvider>
  );
};

export default Surveys;
