import styled from 'styled-components';
import { theme } from '../../../globalStyles/theme';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleStyled = styled.div`
  color: ${theme.colorWhite};
  font-size: ${theme.size};
  line-height: ${theme.lineHeight};
  margin-bottom: ${theme.spacingSmall};
  text-align: center;
`;

export const IconsWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${theme.sizeLarge};
  & > div {
    padding: 0 ${theme.spacingSmall};
    & > * {
      &:hover {
        opacity: 0.8;
      }
    }
  }
  @media (min-width: ${theme.breakpoints.xs}px) {
    font-size: ${theme.sizeXlLarge};
  }
`;

export const LinkIcon = styled.a``;
