import React from 'react';
import { Popover } from 'antd';
import { Wrapper, SelectedListStyled, ListItemStyled } from './styles';
import { IconEdit, IconInfo } from '../../../../icons';
import AddSurvey from './AddSurvey';
import SurveyPoints from './SurveyPoints';
import { MAX_SURVEYS_LENGTH, COLORS, EDIT_SURVEY_TYPE, NONE_SURVEY_TYPE } from '../../helper';
import { useSurvey } from '../../useSurvey';

const Values = () => {
  const [
    { data, selectedSurveys, surveyType },
    { setSurveyType, setEditableSurveyId, sliceData, updateSurveyAction },
  ] = useSurvey();

  const editHandle = (data, selectedSurvey) => {
    updateSurveyAction(data, selectedSurvey.group, selectedSurvey.name, false);
    setEditableSurveyId(selectedSurvey.id);
    setSurveyType(EDIT_SURVEY_TYPE);
  };

  return (
    <Wrapper>
      {!!selectedSurveys.length ? (
        <SelectedListStyled>
          {sliceData(selectedSurveys).map(selectedSurvey => (
            <ListItemStyled borderColor={COLORS[selectedSurvey.group]} key={selectedSurvey.id}>
              {selectedSurvey.description ? (
                <Popover
                  title={selectedSurvey.description}
                  color="black"
                  trigger="click"
                  placement="topLeft"
                  overlayClassName="custom-popover-style"
                >
                  <figure className="icon-info">
                    <IconInfo />
                  </figure>
                </Popover>
              ) : null}
              <div>{selectedSurvey.name.replace('/', ' / ')}</div>
              <figure className="icon-edit" onClick={() => editHandle(data, selectedSurvey)}>
                <IconEdit />
              </figure>
            </ListItemStyled>
          ))}
        </SelectedListStyled>
      ) : null}

      {surveyType === NONE_SURVEY_TYPE ? (
        selectedSurveys.length !== MAX_SURVEYS_LENGTH ? (
          <AddSurvey />
        ) : null
      ) : (
        <SurveyPoints />
      )}
    </Wrapper>
  );
};

export default Values;
