import React, { useState } from 'react';
import {
  FormStyled,
  InputStyled,
  TextAreaStyled,
  FormFieldStyled,
  AddButtonStyled,
  ButtonsStyled,
  CancelButtonStyled,
} from './styles';
import { isEmpty } from 'lodash';
import { Formik } from 'formik';
import nextId from 'react-id-generator';
import { useSurvey } from '../../../../useSurvey';
import { ADD_CUSTOM_KEY, NONE_SURVEY_TYPE } from '../../../../helper';

const AddCustomForm = ({ setOpenKeys }) => {
  const [{ data, surveyType }, { addSurvey, editSurvey, setSurveyType }] = useSurvey();

  const [disabled, setDisabled] = useState(true);

  const onHandleSurvey = (data, values) => {
    const strategy = {
      add: addSurvey,
      edit: editSurvey,
    };

    const newSurvey = {
      id: nextId(),
      description: values.textarea,
      name: values.title,
      group: ADD_CUSTOM_KEY,
    };

    strategy?.[surveyType](data, newSurvey);

    setSurveyType(NONE_SURVEY_TYPE);
  };

  const validate = values => {
    let errors = {};

    if (!values.title.trim().length) {
      errors.title = 'title';
    }

    setDisabled(!isEmpty(errors));

    return errors;
  };

  return (
    <Formik
      initialValues={{
        title: '',
      }}
      validate={validate}
      onSubmit={(values, actions) => {
        setOpenKeys([]);
        onHandleSurvey(data, values);
        actions.resetForm();
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <FormStyled onSubmit={handleSubmit} className="ant-form ant-form-vertical">
          <FormFieldStyled label="My Custom Term">
            <InputStyled
              maxLength={60}
              onChange={e => {
                const value = e.target.value;
                setFieldValue('title', value);
              }}
              value={values.title}
            />
          </FormFieldStyled>

          <ButtonsStyled>
            <CancelButtonStyled onClick={() => setOpenKeys([])}> Cancel </CancelButtonStyled>
            <AddButtonStyled type="submit" disabled={disabled}>
              Add Custom Term
            </AddButtonStyled>
          </ButtonsStyled>
        </FormStyled>
      )}
    </Formik>
  );
};

export default AddCustomForm;
