import React from 'react';
import { IconLinkedin, IconFacebook, IconTwitter } from '../../../icons';
import { TitleStyled, IconsWrapperStyled, Wrapper } from './styles';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import ShareResult from '../ShareResult';

const Footer = ({ authToken }) => {
  return (
    <Wrapper>
      <ShareResult authToken={authToken} />

      <div>
        <TitleStyled>Share this tool!</TitleStyled>
        <IconsWrapperStyled>
          <div>
            <TwitterShareButton url="https://www.mostfinder.com/">
              <IconTwitter />
            </TwitterShareButton>
          </div>
          <div>
            <FacebookShareButton url="https://www.mostfinder.com/">
              <IconFacebook />
            </FacebookShareButton>
          </div>
          <div>
            <LinkedinShareButton url="https://www.mostfinder.com/">
              <IconLinkedin />
            </LinkedinShareButton>
          </div>
        </IconsWrapperStyled>
      </div>
    </Wrapper>
  );
};

export default Footer;
