import styled from 'styled-components';
import { theme } from '../../../globalStyles/theme';

export const ListStyled = styled.ul`
  && {
    padding: 0 ${theme.spacing};
    margin: ${theme.spacingLarge} 0;
    list-style: none;
    li {
      display: flex;
      align-items: center;
      font-size: ${theme.sizeSmall};
      line-height: ${theme.lineHeightSmall};
      font-family: ${theme.fontNunito};
      & + * {
        margin-top: ${theme.spacingSmall};
      }

      .label {
        border-radius: ${theme.borderRadius};
        box-shadow: ${theme.boxShadow};
        padding: ${theme.spacingSmall};
        margin-right: ${theme.spacing};
        font-weight: bold;
        background-color: #7e3af2;
      }
      .label-1 {
        background-color: #7e3af2;
      }
      .label-2 {
        background-color: #6c2bd9;
      }
      .label-3 {
        background-color: #5521b5;
      }
      .label-4 {
        background-color: #4a1d96;
      }
    }
  }
`;
