import styled from 'styled-components';
import { theme } from '../../../../../../globalStyles/theme';
import { Button } from '../../../../../../globalStyles/utils';
import { Form as FormAnt, Input } from 'antd';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  width: 100%;
  background-color: #19191a;
  padding: ${theme.spacingSmall};
  border-radius: ${theme.borderRadius};
`;

export const FormFieldStyled = styled(FormAnt.Item)`
  && {
    .ant-form-item-label {
      padding: 0;
      label {
        color: ${theme.colorWhite};
        font-size: ${theme.sizeSmall};
        line-height: ${theme.lineHeightSmall};
        font-family: ${theme.fontNunito};
        font-weight: 400;
      }
    }
  }
`;

export const InputStyled = styled(Input)`
  box-shadow: none !important;
  border: none !important;
  border-radius: ${theme.borderRadius} !important; ;
`;

export const TextAreaStyled = styled(Input.TextArea)`
  box-shadow: none !important;
  border: none !important;
  border-radius: ${theme.borderRadius} !important;
`;

export const ButtonsStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -${theme.spacingXsSmall};
  & > * {
    flex-grow: 1;
  }
`;

export const AddButtonStyled = styled(Button)`
  padding: ${theme.spacingSmall};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: ${theme.borderRadius};
  background-color: ${theme.colorBunting};
  color: #cfd8e3;
  height: 40px;
  font-size: ${theme.sizeSmall};
  line-height: ${theme.lineHeightSmall};
  margin: 0 ${theme.spacingXsSmall};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colorBuntingLighten};
  }
`;

export const CancelButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacingSmall};
  margin: 0 ${theme.spacingXsSmall};
  height: 40px;
  border: 1px solid ${theme.colorBuntingLighten};
  border-radius: ${theme.borderRadius};
  color: #cfd8e3;
  font-size: ${theme.sizeSmall};
  line-height: ${theme.lineHeightSmall};
  &:hover {
    background-color: ${theme.colorBuntingLighten};
  }
`;
