import React from 'react';
import { ListStyled, ListItem, ListTitleStyled } from './styles';

const ListOthers = ({ others }) => {
  return (
    <>
      <ListTitleStyled>the rest ...</ListTitleStyled>
      <ListStyled>
        {others.map(other => (
          <ListItem key={other}>{other}</ListItem>
        ))}
      </ListStyled>
    </>
  );
};

export default ListOthers;
