import styled from 'styled-components';
import { theme } from '../../../../../globalStyles/theme';

export const AddButtonWrapperStyled = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${theme.spacingSmall};
`;

export const DescriptionWrapperStyled = styled.div`
  margin-left: ${theme.spacing};
`;

export const FigureArrowLeft = styled.figure`
  margin-left: ${theme.spacingSmall};
  margin-top: ${theme.spacingSmall};
  font-size: 70px;
  @media (min-width: ${theme.breakpoints.xs}px) {
    font-size: 90px;
  }
`;

export const AddButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  cursor: pointer;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '163px')};
  height: 40px;
  border: 2px dashed ${theme.borderColorGray};
  color: ${theme.borderColorGray};
  border-radius: ${theme.borderRadius};
  font-size: ${theme.sizeXsLarge};
  margin-bottom: ${theme.spacingSmall};
`;
