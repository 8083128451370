import styled from 'styled-components';
import { theme } from '../../../globalStyles/theme';

export const ListTitleStyled = styled.h2`
  color: ${theme.colorWhite};
  font-family: ${theme.fontFractul};
  font-size: ${theme.size};
  line-height: ${theme.lineHeight};
  font-weight: 500;
`;

export const ListStyled = styled.ul`
  flex-shrink: 0;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -${theme.spacingXsSmall};
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin: ${theme.spacingXsSmall};
  width: calc(50% - ${theme.spacingSmall});
  height: 40px;
  border-radius: ${theme.borderRadiusLarge};
  background: linear-gradient(180deg, #302e30 0%, #2b2b2c 100%);
  font-size: ${theme.size};
  line-height: ${theme.lineHeight};
  font-weight: bold;
  color: ${theme.colorWhite};
  padding: ${theme.spacingSmall};

  & + li {
    margin-top: ${theme.spacingSmall};
  }
`;
