import React from 'react';
import Layout from '../../components/Layout';
import Heading from '../../components/Heading';
import Footer from './Footer';
import ListWinners from './ListWinners';
import ListOthers from './ListOthers';
import { NextStepLink } from './styles';
import Hints from './Hints';

const Completed = ({ results, authToken, videoLink }) => {
  const headingContent = {
    title: 'Your Top 4',
    descriptions: ['Here are the top things you want from work'],
  };

  const { others, ...rest } = results;
  const winners = Object.values(rest);
  return (
    <Layout
      body={
        <>
          <Heading content={headingContent} />
          <ListWinners winners={winners} />
          <ListOthers others={others} />
          <Hints />
          <NextStepLink target="_blank" href={videoLink} fullWidth>
            Next Step: Find Out How >>>
          </NextStepLink>
        </>
      }
      footer={<Footer authToken={authToken} />}
    />
  );
};

export default Completed;
