import styled from 'styled-components';
import { theme } from '../../../globalStyles/theme';

export const WrapperStyled = styled.div``;

export const TitleStyled = styled.div`
  color: ${theme.colorMelrose};
  font-family: ${theme.fontBeausite};
  font-size: ${theme.sizeSmall};
  line-height: ${theme.lineHeightSmall};
  margin-bottom: ${theme.spacingSmall};
  font-weight: 500;
  @media (min-width: ${theme.breakpoints.xs}px) {
    font-size: ${theme.size};
    line-height: ${theme.lineHeight};
  }
`;

export const ListStyled = styled.ol`
  padding: 0 0 0 25px;
  margin: 0;
  li {
    color: ${theme.colorMelrose};
    font-family: ${theme.fontBeausite};
    font-size: ${theme.sizeSmall};
    line-height: ${theme.lineHeightSmall};
    font-weight: 500;
    @media (min-width: ${theme.breakpoints.xs}px) {
      font-size: ${theme.size};
      line-height: ${theme.lineHeight};
    }
  }
`;
