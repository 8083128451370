import React, { useMemo } from 'react';
import { WrapperStyled, TitleStyled, RoundsStyled, RoundStyled } from './styles';
import { theme } from '../../../globalStyles/theme';
import CompletedRound from '../CompletedRound';

const Rounds = ({ roundNumber, completed }) => {
  const roundIndexes = [1, 2, 3];
  const roundsData = {
    winners: {
      color: theme.colorGreen,
      titles: ['Winners Bracket'],
      showRounds: true,
    },
    doubleElimination: {
      color: theme.colorOrangeDarken,
      titles: ['Elimination Bracket'],
      showRounds: true,
    },
    final: {
      color: theme.colorYellowLighten,
      titles: ['Championship Match!', 'Final Round'],
      showRounds: false,
    },
  };

  const { color, titles, showRounds, activeRound } = useMemo(() => {
    switch (roundNumber) {
      case 1:
        return { ...roundsData.winners, activeRound: 1 };
      case 2:
        return { ...roundsData.winners, activeRound: 2 };
      case 3:
        return { ...roundsData.winners, activeRound: 3 };
      case 4:
        return { ...roundsData.doubleElimination, activeRound: 1 };
      case 5:
        return { ...roundsData.doubleElimination, activeRound: 2 };
      case 6:
        return { ...roundsData.doubleElimination, activeRound: 3 };
      case 7:
        return { ...roundsData.final, activeRound: 7 };
      default:
        return { ...roundsData.winners, activeRound: 1 };
    }
  }, [roundNumber]);

  return (
    <WrapperStyled>
      {titles.map(title => (
        <TitleStyled key={title} color={color}>
          {title}
        </TitleStyled>
      ))}
      <RoundsStyled>
        {showRounds
          ? roundIndexes.map(index => (
              <RoundStyled color={color} active={index === activeRound} key={index}>
                Round {index}
              </RoundStyled>
            ))
          : null}
      </RoundsStyled>
      <CompletedRound show={completed} roundNumber={activeRound} />
    </WrapperStyled>
  );
};
export default Rounds;