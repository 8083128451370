import React, { useState } from 'react';
import { bodyStyle, LinkTitleStyled, ModalStyled, FormStyled, FormFieldStyled, InputStyled } from './styles';
import { IconEmails } from '../../../icons';
import { shareMail } from '../../../api';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { PrimaryButton } from '../../../globalStyles/utils';

const ShareResult = ({ authToken }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const validate = values => {
    let errors = {};

    if (!values.email) {
      errors.email = 'Enter your email address';
    } else if (!/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    setDisabled(!isEmpty(errors));

    return errors;
  };
  return (
    <>
      <LinkTitleStyled onClick={showModal}>
        <span>Share your results</span>
        <figure>
          <IconEmails height="1em" width="1em" />
        </figure>
      </LinkTitleStyled>
      <ModalStyled
        title="Share your results"
        visible={isModalVisible}
        closable={false}
        footer={null}
        bodyStyle={bodyStyle}
        onCancel={handleCancel}
        centered
        destroyOnClose={true}
      >
        <Formik
          initialValues={{
            email: '',
          }}
          validate={validate}
          onSubmit={(values, actions) => {
            const body = {
              email_address: values.email,
            };
            shareMail(authToken, body).then(() => {
              handleCancel();
              actions.resetForm();
            });
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <FormStyled onSubmit={handleSubmit} className="ant-form ant-form-vertical">
              <FormFieldStyled label="Email">
                <InputStyled
                  onChange={e => {
                    const value = e.target.value;
                    setFieldValue('email', value);
                  }}
                  value={values.email}
                />
              </FormFieldStyled>
              <PrimaryButton type="submit" fullWidth disabled={disabled}>
                Share
              </PrimaryButton>
            </FormStyled>
          )}
        </Formik>
      </ModalStyled>
    </>
  );
};

export default ShareResult;
