import styled from 'styled-components';
import { theme } from '../../../globalStyles/theme';

export const BodyStyled = styled.div`
  position: relative;
  margin-top: ${theme.headerHeight};
  margin-bottom: ${({ hasFooter }) => hasFooter && theme.footerHeight};
  flex-grow: 1;
  z-index: 1;
  & > * {
    position: relative;
    z-index: 1;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 200px;
    background-image: ${theme.gradientPurple};
    z-index: 0;
  }
`;
