import React from 'react';
import { Wrapper, Description } from './styles';

const Hints = () => {
  const hintLists = ['Now that you know what matters most.', 'Find out how to go get the job you want.'];
  return (
    <Wrapper>
      {hintLists.map(item => (
        <Description key={item}>{item}</Description>
      ))}
    </Wrapper>
  );
};

export default Hints;
