import React from 'react';
import { LayoutStyled } from './styles';
import Indexes from './Indexes';
import Values from './Values';

const PickList = () => {
  return (
    <LayoutStyled>
      <Indexes />
      <Values />
    </LayoutStyled>
  );
};

export default PickList;
