import styled from 'styled-components';
import { theme } from '../../globalStyles/theme';

export const LinkStarted = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: ${theme.borderRadius};
  background-color: ${theme.backgroundButton};
  font-weight: 700;
  font-size: ${theme.sizeXsLarge};
  letter-spacing: 0;
  line-height: ${theme.lineHeightXsLarge};
  text-align: center;
  padding: ${theme.spacingMedium} ${theme.spacing};
  &:hover {
    background-color: ${theme.backgroundButtonHover};
  }
`;

export const VideoTrigger = styled.a`
  border: 1px solid #7e3af2;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin: ${theme.spacing} 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  padding-left: ${theme.spacingSmall};

  & > figure {
    display: flex;
    margin-right: ${theme.spacingSmall};
    font-size: ${theme.sizeXsLarge};
  }
  &:hover {
    background-color: #7e3af2;
  }
`;
