import React from 'react';
import { Container } from '../../../globalStyles/utils';
import { FooterStyled, WrapperStyled } from './styles';

const Footer = ({ children }) => (
  <FooterStyled>
    <WrapperStyled>
      <Container>{children}</Container>
    </WrapperStyled>
  </FooterStyled>
);

export default Footer;
