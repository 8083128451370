import React, { useState } from 'react';
import Layout from '../../components/Layout';
import Heading from '../../components/Heading';
import ListHint from './ListHints';
import VersusList from './VersusList';
import { PrimaryButton } from '../../globalStyles/utils';
import { readyToPlayoff } from '../../api';
import { VersusTitleStyled } from './styles';

const Tutorial = ({ authToken, surveyId }) => {
  const headingContent = {
    title: 'mostfinder Playoffs!',
    descriptions: [
      'We are now ready to put the things you want most head to head in a playoff system that will give you clarity on what you want most.',
      'You will be presented with two choices and you need to select one. This will be hard at times, don’t worry you can re-take it over and over to get the clarity you want.',
    ],
  };

  const [selectedRadio, setSelectedRadio] = useState(null);

  return (
    <Layout
      body={
        <>
          <Heading content={headingContent} size="medium"/>
          <VersusTitleStyled>Try it out - Select One</VersusTitleStyled>
          <VersusList setSelectedRadio={setSelectedRadio} />
          <ListHint />
        </>
      }
      footer={
        <PrimaryButton
          type="submit"
          disabled={!selectedRadio}
          fullWidth
          onClick={() => readyToPlayoff(authToken, surveyId)}
        >
          Start the Playoffs!
        </PrimaryButton>
      }
    />
  );
};

export default Tutorial;
