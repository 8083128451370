import React, { useState } from 'react';
import Layout from '../../components/Layout';
import Footer from './Footer';
import VersusList from '../../components/VersusList';
import { Formik, Form } from 'formik';
import { difference, isEmpty } from 'lodash';
import Rounds from './Rounds';
import { submitRound } from '../../api';

const InRounds = ({ authToken, round_number, roundAttributes, surveyId }) => {
  const [roundNumber, setRoundNumber] = useState(round_number ?? 1);
  const [completed, setCompleted] = useState(false);
  const [attributes, setAttributes] = useState(roundAttributes ?? []);
  const [disabled, setDisabled] = useState(true);

  const validate = values => {
    let errors = {};

    if (Object.values(values).length !== attributes.length / 2) {
      errors.values = 'empty';
    }

    setDisabled(!isEmpty(errors));

    return errors;
  };

  return (
    <Formik
      initialValues={{}}
      validate={validate}
      onSubmit={(values, { resetForm }) => {
        const winners = Object.values(values);
        const losers = difference(attributes, winners);

        const body = {
          winners,
          losers,
        };
        setDisabled(true);
        setCompleted(true);

        submitRound(authToken, surveyId, body).then(({ round_attributes, round_number, redirect_path }) => {
          if (roundNumber === 7) {
            window.location.href = redirect_path;
          } else {
            setTimeout(() => {
              setCompleted(false);
              setRoundNumber(round_number);
              setAttributes(round_attributes);
              resetForm();
            }, 1000);
          }
        });
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Layout
            body={
              <>
                <Rounds roundNumber={roundNumber} completed={completed} setCompleted={setCompleted} />
                <VersusList attributes={attributes} values={values} setFieldValue={setFieldValue} />
              </>
            }
            footer={<Footer disabled={disabled} />}
          />
        </Form>
      )}
    </Formik>
  );
};

export default InRounds;
