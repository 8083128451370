import React from 'react';
import Layout from '../../components/Layout';
import StepsList from './StepsList';
import { LinkStarted, VideoTrigger } from './styles';
import Heading from '../../components/Heading';
import { IconPlay } from '../../icons';
import { letsStarted } from '../../api';

const Home = ({ authToken, videoLink }) => {
  const headingContent = {
    title: 'Welcome!',
    descriptions: [
      'In less than 5 minutes you will know the secret to what you want most from work.',
      'This is a key to happiness, passion, and job satisfaction.',
    ],
  };

  return (
    <Layout
      body={
        <>
          <Heading content={headingContent} />

          <StepsList />

          <LinkStarted onClick={() => letsStarted(authToken)}>Let’s Get Started!</LinkStarted>

          <VideoTrigger target="_blank" href={videoLink} fullWidth>
            Watch a quick explainer video
          </VideoTrigger>
        </>
      }
    />
  );
};

export default Home;
