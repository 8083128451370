import styled from 'styled-components';
import { theme } from './theme';

export const BodyStyled = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 300px;
    background-image: linear-gradient(180deg, #4a1d96 0%, #19191a 100%);
    z-index: -1;
  }
`;

export const DescriptionStyled = styled.p`
  font-family: ${theme.fontBeausiteClear};
  color: ${theme.colorWhite};
  font-size: ${({ size }) => (size === 'large' ? theme.sizeXsLarge : theme.sizeSmall)};
  line-height: ${({ size }) => (size === 'large' ? theme.lineHeightXsLarge : theme.lineHeightSmall)};
  text-align: ${({ align }) => align};
  h1 + & {
    margin-top: 0;
  }
  @media (min-width: ${theme.breakpoints.xs}px) {
    font-size: ${({ size }) => (size === 'large' ? theme.sizeLarge : theme.size)};
    line-height: ${({ size }) => (size === 'large' ? theme.lineHeightLarge : theme.lineHeight)};
  }
`;

export const H1Styled = styled.h1`
  font-family: ${theme.fontFractul};
  font-weight: 500;
  font-size: ${({ size }) => (size === 'large' ? theme.sizeLarge : theme.sizeXsLarge)};
  line-height: ${theme.lineHeightXLarge};
  margin: 0;
  @media (min-width: ${theme.breakpoints.xs}px) {
    font-size: ${({ size }) => (size === 'large' ? theme.sizeXlLarge : theme.sizeLarge)};
    line-height: ${theme.lineHeightXlLarge};
  }
`;

export const WinnerAttributeStyled = styled.h2`
  font-family: ${theme.fontFractul};
  color: ${theme.colorYellowLighten};
  font-weight: 500;
  font-size: ${theme.sizeXlLarge};
  line-height: ${theme.lineHeightXLarge};
  margin: ${theme.spacingMedium} 0 ${theme.spacing};
  text-align: center;
  @media (min-width: ${theme.breakpoints.xs}px) {
    font-size: 56px;
    line-height: ${theme.lineHeightXlLarge};
  }
`;

export const Container = styled.div`
  max-width: 414px;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${theme.spacing};
`;

export const Button = styled.button`
  display: inline-block;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 0;
  font-size: ${theme.sizeSmall};
  line-height: inherit;
  font-family: inherit;
  padding: ${theme.spacingSmall} ${theme.spacing};
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.45;
    background-image: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    pointer-events: none !important;
  }
`;

export const PrimaryButton = styled(Button)`
  border-radius: ${theme.borderRadius};
  background-color: ${theme.colorGreen};
  color: ${theme.colorWhite};
  border: none;
  width: ${({ fullWidth }) => fullWidth && '100%'};
  &:hover {
    background-color: ${theme.colorGreenDarken};
  }

  &:disabled {
    background-color: #64748b;
    color: #cfd8e3;
  }
`;
