import { theme } from '../../globalStyles/theme';

export const MAX_SURVEYS_LENGTH = 8;
export const EDIT_SURVEY_TYPE = 'edit';
export const ADD_SURVEY_TYPE = 'add';
export const NONE_SURVEY_TYPE = 'none';
export const ADD_CUSTOM_KEY = 'AddCustom';

export const COLORS = {
  Company: theme.colorOrangeDarken,
  Job: theme.colorYellowDarken,
  Team: theme.colorGreenDarken,
  Personal: theme.colorTealDarken,
  Returns: theme.colorBlueDarken,
  Development: theme.colorPurpleDarken,
  AddCustom: theme.colorBuntingLighten,
};
