import styled from 'styled-components';
import { theme } from '../../../../../../globalStyles/theme';

export const Top = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 101px;
  font-family: ${theme.fontBeausite};
  @media (min-width: ${theme.breakpoints.xs}px) {
    width: 131px;
  }
`;

export const FigureOrange = styled.figure`
  font-size: 70px;
  margin-top: -${theme.spacingSmall};
  svg {
    transform: rotate(-40deg);
  }
  @media (min-width: ${theme.breakpoints.xs}px) {
    font-size: 90px;
  }
`;

export const FigureYellow = styled.figure`
  font-size: 70px;
  margin-top: -40px;
  margin-left: -${theme.spacing};
  margin-bottom: -${theme.spacing};
  svg {
    transform: rotate(-38deg);
  }
  @media (min-width: ${theme.breakpoints.xs}px) {
    font-size: 90px;
  }
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

export const FigureBunting = styled.figure`
  font-size: 70px;
  margin-bottom: ${theme.spacing};
  svg {
    transform: scaleY(-1) rotate(-158deg);
  }
  @media (min-width: ${theme.breakpoints.xs}px) {
    font-size: 90px;
  }
`;
