import styled from 'styled-components';
import { theme } from '../../../globalStyles/theme';

export const Wrapper = styled.div`
  margin: ${theme.spacing} 0;
`;

export const Description = styled.p`
  margin: 0;
  text-align: center;
  font-size: ${theme.sizeSmall};
  line-height: ${theme.lineHeightSmall};
  color: ${theme.colorYellow};

  @media (min-width: ${theme.breakpoints.xs}px) {
    font-size: ${theme.size};
    line-height: ${theme.lineHeight};
  }
`;
