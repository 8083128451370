import { Menu } from 'antd';
import styled from 'styled-components';
import { theme } from '../../../../../globalStyles/theme';

const colorStrategy = {
  Company: { mainColor: theme.colorOrange, darkenColor: theme.colorOrangeDarken, textBackground: '#FCD9BD' },
  Job: { mainColor: theme.colorYellow, darkenColor: theme.colorYellowDarken, textBackground: '#FDF6B2' },
  Team: { mainColor: theme.colorGreen, darkenColor: theme.colorGreenDarken, textBackground: '#BCF0DA' },
  Personal: { mainColor: theme.colorTeal, darkenColor: theme.colorTealDarken, textBackground: '#AFECEF' },
  Returns: { mainColor: theme.colorBlue, darkenColor: theme.colorBlueDarken, textBackground: '#C3DDFD' },
  Development: { mainColor: theme.colorPurple, darkenColor: theme.colorPurpleDarken, textBackground: '#CDDBFE' },
  AddCustom: { mainColor: theme.colorBunting, darkenColor: theme.colorBuntingLighten },
};

export const WrapperStyled = styled.div`
  position: relative;
`;

export const MenuStyled = styled(Menu)`
  background-color: unset;
  border: none;
`;

export const SubMenuStyled = styled(Menu.SubMenu)`
  && {
    display: flex;
    width: 100%;
    position: relative;
    .ant-menu-submenu-title {
      display: flex;
      flex-basis: 50%;
      background-color: ${({ color }) => colorStrategy[color]?.mainColor};
      padding: 0 !important;
      margin: 0 0 ${theme.spacingSmall};
      text-transform: capitalize;
    }
    .ant-menu-title-content {
      display: flex;
      align-self: stretch;
      flex-grow: 1;
      justify-content: space-between;
      color: ${theme.colorWhite};
      margin: 0;
      padding: 0;
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        font-weight: bold;
        white-space: normal;
        text-align: center;
        font-size: ${theme.sizeXsSmall};
        line-height: ${theme.lineHeightXsSmall};
        padding: ${theme.spacingSmall} ${theme.spacingXsSmall};
        @media (min-width: ${theme.breakpoints.xs}px) {
          font-size: ${theme.sizeSmall};
          line-height: ${theme.lineHeightSmall};
          padding: ${theme.spacingSmall};
        }
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${theme.spacingSmall};
        background-color: ${({ color }) => colorStrategy[color]?.darkenColor};
        svg {
          transition: transform 0.3s;
          transform: rotate(90deg);
        }
      }
    }
    &.ant-menu-submenu-open {
      opacity: unset;
    }
    &:not(&.ant-menu-submenu-open) {
      opacity: 0.5;
      &:hover {
        opacity: unset;
      }
    }
    &.ant-menu-submenu-open {
      .ant-menu-title-content {
        .icon {
          svg {
            transform: unset;
          }
        }
      }
    }
    .ant-menu-submenu-arrow {
      display: none;
    }
    .ant-menu-inline {
      position: absolute;
      z-index: 1;
      width: 50%;
      right: 0;
      padding-left: ${theme.spacingSmall};
    }
  }
`;

export const ItemStyled = styled(Menu.Item)`
  && {
    padding: 0 !important;
    margin: 0 0 ${theme.spacingSmall};
    text-transform: capitalize;
    .ant-menu-title-content {
      display: flex;
      background-color: ${({ color }) => colorStrategy[color]?.textBackground};
      .text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        flex-grow: 1;
        font-weight: bold;
        font-size: ${theme.sizeXsSmall};
        line-height: ${theme.lineHeightXsSmall};
        padding: 0 ${theme.spacingXsSmall};
        border: 1px dashed ${({ color }) => colorStrategy[color]?.darkenColor};
        color: ${({ color }) => colorStrategy[color]?.darkenColor};
        white-space: normal;

        @media (min-width: ${theme.breakpoints.xs}px) {
          font-size: ${theme.sizeSmall};
          line-height: ${theme.lineHeightSmall};
          padding: 0 6px;
        }
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.colorWhite};
        font-size: ${theme.sizeMedium};
        padding: ${theme.spacingSmall};
        background-color: ${({ color }) => colorStrategy[color]?.darkenColor};
        margin-right: -2px;
      }
    }
    &.ant-menu-item-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`;

export const SubMenuSecondaryStyled = styled(SubMenuStyled)`
  && {
    display: block;
    .ant-menu-submenu-title {
      max-width: 50%;
    }
    .ant-menu-inline {
      padding: 0;
      position: static;
      width: 100%;
    }
  }
`;

export const ItemSecondaryStyled = styled(Menu.Item)`
  &&& {
    padding: 0 !important;
    margin: 0 0 ${theme.spacingSmall};
    height: auto !important;
    background-color: unset !important;
    width: 100%;
    &:after {
      border: none !important;
    }
    .ant-menu-title-content {
      line-height: inherit;
      height: auto !important;

      .ant-menu-submenu-title {
        height: auto !important;
      }
    }
  }
`;
