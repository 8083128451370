import styled from 'styled-components';
import { theme } from '../../../globalStyles/theme';

export const ListStyled = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0 auto;
  .index {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.backgroundPurple};
    border: 2px solid ${theme.colorWhite};
    font-family: ${theme.fontBeausite};
    font-size: ${theme.sizeXsLarge};
    line-height: ${theme.lineHeightXsLarge};
    margin-right: ${theme.spacing};
    font-weight: bold;
    color: ${theme.colorWhite};
    border-radius: 50%;
  }

  .label {
    width: 171px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: ${theme.borderRadiusLarge};
    background: linear-gradient(180deg, #302e30 0%, #2b2b2c 100%);
    font-size: ${theme.size};
    line-height: ${theme.lineHeight};
    font-weight: bold;
    color: ${theme.colorWhite};
    padding: ${theme.spacingSmall};
  }

  & + li {
    margin-top: ${theme.spacingSmall};
  }
`;
