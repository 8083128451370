import styled from 'styled-components';
import { theme } from '../../globalStyles/theme';
import { Form as FormAnt, Input } from 'antd';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  width: 100%;
`;

export const FormFieldStyled = styled(FormAnt.Item)`
  && {
    .ant-form-item-label {
      padding: 0;
      label {
        color: ${theme.colorWhite};
        font-size: ${theme.sizeSmall};
        line-height: ${theme.lineHeightSmall};
        font-family: ${theme.fontNunito};
        font-weight: 400;
      }
    }
  }
`;

export const InputStyled = styled(Input)`
  box-shadow: none !important;
  border: none !important;
  border-radius: ${theme.borderRadius} !important; ;
`;
