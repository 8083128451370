import styled from 'styled-components';
import bgImage from '../../../../assets/images/bg_bottom.svg';

export const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 200px;
    left: 0;
    background-image: url(${bgImage});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
`;
