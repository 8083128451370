import styled from 'styled-components';
import { Form as FormAnt, Input, Modal } from 'antd';
import { theme } from '../../../globalStyles/theme';
import { Form } from 'formik';

export const LinkTitleStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: ${theme.colorWhite};
  span {
    color: ${theme.colorWhite};
    font-size: ${theme.size};
    line-height: ${theme.lineHeight};
    margin-bottom: ${theme.spacingSmall};
  }
  figure {
    margin-left: ${theme.spacing};
    font-size: ${theme.sizeLarge};
    @media (min-width: ${theme.breakpoints.xs}px) {
      font-size: ${theme.sizeXlLarge};
    }
  }

  &:hover {
    color: ${theme.colorPurple};
  }
`;

export const ModalStyled = styled(Modal)`
  &&& {
    position: relative;
    .ant-modal-content {
      .ant-modal-header {
        background-color: ${theme.background};
        .ant-modal-title {
          color: ${theme.colorWhite};
          font-size: ${theme.sizeMedium};
        }
      }
      .ant-modal-body {
        position: relative;
        background-color: ${theme.background};
      }
    }
  }
`;

export const bodyStyle = {};

export const FormStyled = styled(Form)`
  width: 100%;
`;

export const FormFieldStyled = styled(FormAnt.Item)`
  && {
    .ant-form-item-label {
      padding: 0;
      label {
        color: ${theme.colorWhite};
        font-size: ${theme.sizeSmall};
        line-height: ${theme.lineHeightSmall};
        font-family: ${theme.fontNunito};
        font-weight: 400;
      }
    }
  }
`;

export const InputStyled = styled(Input)`
  box-shadow: none !important;
  border: none !important;
  border-radius: ${theme.borderRadius} !important; ;
`;
