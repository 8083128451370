import styled from 'styled-components';
import { theme } from '../../globalStyles/theme';

export const VersusTitleStyled = styled.div`
  color: ${theme.colorMelrose};
  font-family: ${theme.fontBeausite};
  font-size: ${theme.sizeMedium};
  line-height: ${theme.lineHeightMedium};
  margin-top: ${theme.spacingLarge};
  margin-bottom: -${theme.spacing};
  text-align: center;
  font-weight: 500;
`;
