import React from 'react';
import { DescriptionStyled } from '../../../../../globalStyles/utils';
import { AddButtonWrapperStyled, AddButtonStyled, FigureArrowLeft, DescriptionWrapperStyled } from './styles';
import { IconAdd, IconLongArrowLeft } from '../../../../../icons';
import { useSurvey } from '../../../useSurvey';
import { ADD_SURVEY_TYPE } from '../../../helper';

const AddSurvey = () => {
  const [{ selectedSurveys }, { setSurveyType }] = useSurvey();

  const hasListValue = selectedSurveys.length === 0;
  const isOneListValue = selectedSurveys.length === 1;

  const onClickAddButton = () => {
    setSurveyType(ADD_SURVEY_TYPE);
  };

  return hasListValue ? (
    <>
      <AddButtonWrapperStyled>
        <AddButtonStyled onClick={onClickAddButton}>
          <IconAdd />
        </AddButtonStyled>
        <FigureArrowLeft>
          <IconLongArrowLeft />
        </FigureArrowLeft>
      </AddButtonWrapperStyled>
      <DescriptionWrapperStyled>
        <DescriptionStyled>
          Start adding the things you want most from work, simply click the add button.
        </DescriptionStyled>
        <DescriptionStyled>Add them one by one until all 8 are filled.</DescriptionStyled>
        <DescriptionStyled>You can make changes until you are ready to go to the next step.</DescriptionStyled>
      </DescriptionWrapperStyled>
    </>
  ) : isOneListValue ? (
    <>
      <AddButtonWrapperStyled>
        <AddButtonStyled onClick={onClickAddButton}>
          <IconAdd />
        </AddButtonStyled>
        <FigureArrowLeft>
          <IconLongArrowLeft />
        </FigureArrowLeft>
      </AddButtonWrapperStyled>
      <DescriptionWrapperStyled>
        <DescriptionStyled>Excellent first pick! Now go through and select your other 7 terms.</DescriptionStyled>
        <DescriptionStyled>If you want to change a selected term, just tap it.</DescriptionStyled>
      </DescriptionWrapperStyled>
    </>
  ) : (
    <AddButtonStyled fullWidth onClick={onClickAddButton}>
      <IconAdd />
    </AddButtonStyled>
  );
};

export default AddSurvey;
