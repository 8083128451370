import React from 'react';

export const IconAdd = ({ width = '1em', height = '1em' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H24V24H0z" />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M18 8L10 8 10 0 8 0 8 8 0 8 0 10 8 10 8 18 10 18 10 10 18 10z"
        transform="translate(3 3)"
      />
    </g>
  </svg>
);

export const IconEdit = ({ width = '1em', height = '1em' }) => (
  <svg
    width={width}
    height={height}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 512.001 512.001"
    version="1.1"
    viewBox="0 0 512.001 512.001"
    xmlSpace="preserve"
  >
    <path d="M496.063 62.299l-46.396-46.4c-21.199-21.199-55.689-21.198-76.888 0L27.591 361.113c-2.17 2.17-3.624 5.054-4.142 7.875L.251 494.268a15.002 15.002 0 0017.48 17.482L143 488.549c2.895-.54 5.741-2.008 7.875-4.143l345.188-345.214c21.248-21.248 21.251-55.642 0-76.893zM33.721 478.276l14.033-75.784 61.746 61.75-75.779 14.034zm106.548-25.691L59.41 371.721 354.62 76.488l80.859 80.865-295.21 295.232zM474.85 117.979l-18.159 18.161-80.859-80.865 18.159-18.161c9.501-9.502 24.96-9.503 34.463 0l46.396 46.4c9.525 9.525 9.525 24.939 0 34.465z" />
  </svg>
);
export const IconInfo = ({ width = '1em', height = '1em' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" viewBox="0 0 384 384">
    <path d="M192 0C86.129 0 0 86.129 0 192s86.129 192 192 192 192-86.129 192-192S297.871 0 192 0zm0 352c-88.223 0-160-71.777-160-160S103.777 32 192 32s160 71.777 160 160-71.777 160-160 160zm0 0" />
    <path d="M224 288h-16V160c0-8.832-7.168-16-16-16h-24c-8.832 0-16 7.168-16 16s7.168 16 16 16h8v112h-16c-8.832 0-16 7.168-16 16s7.168 16 16 16h64c8.832 0 16-7.168 16-16s-7.168-16-16-16zm0 0M192 112c13.23 0 24-10.77 24-24s-10.77-24-24-24-24 10.77-24 24 10.77 24 24 24zm0 0" />
  </svg>
);

export const IconPlay = ({ width = '1em', height = '1em' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      transform="translate(1 1)"
    >
      <circle cx="11.25" cy="11.25" r="11.25" />
      <path d="M8.25 14.865a1.636 1.636 0 002.712 1.231l5.538-4.844-5.538-4.847A1.635 1.635 0 008.25 7.636v7.229z" />
    </g>
  </svg>
);

export const IconEmails = ({ width = '1em', height = '1em' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 56 50">
    <path
      fill="currentColor"
      d="M10.889 4.667h28v4.666h4.667V4.667A4.667 4.667 0 0038.889 0h-28a4.667 4.667 0 00-4.667 4.667v14h4.667v-14zm21.778 17.11h-28A4.667 4.667 0 000 26.445v18.667a4.667 4.667 0 004.667 4.667h28a4.667 4.667 0 004.666-4.667V26.444a4.667 4.667 0 00-4.666-4.666zm0 23.334h-28v-11.67c1.408 1.07 7.777 5.65 7.777 5.65C13.848 40.18 16.2 42 18.667 42c2.467 0 4.818-1.82 6.222-2.909 0 0 6.368-4.58 7.778-5.65v11.67zm0-17.365c-.23.18-10.87 7.967-11.384 8.318-.826.566-1.857 1.27-2.616 1.27-.915 0-2.206-1.026-3.063-1.705-.331-.264-10.707-8.014-10.937-8.194v-.99h28v1.301zm18.666-15.302h-28a4.667 4.667 0 00-4.666 4.667v1.556h4.666V17.11h28v18.667H40.444v4.666h10.89A4.667 4.667 0 0056 35.778V17.11a4.667 4.667 0 00-4.667-4.667zM42 20.222v6.222h6.222v-6.222H42z"
    />
  </svg>
);

export const IconFacebook = ({ width = '1em', height = '1em' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 56 56">
    <g fill="none" fillRule="evenodd">
      <circle cx="28" cy="28" r="28" fill="#FFF" />
      <path
        fill="#4A8AF0"
        fillRule="nonzero"
        d="M56 28.17C56 12.61 43.468 0 28 0S0 12.609 0 28.17C0 42.23 10.24 53.885 23.625 56V36.314h-7.113V28.17h7.113v-6.206c0-7.06 4.177-10.96 10.576-10.96 3.064 0 6.268.55 6.268.55v6.93h-3.532c-3.477 0-4.562 2.171-4.562 4.399v5.287h7.765L38.9 36.314h-6.524V56C45.761 53.885 56 42.23 56 28.17z"
      />
    </g>
  </svg>
);

export const IconLongArrowLeft = ({ width = '1em', height = '1em', theme = 'default' }) => {
  const themeStrategy = {
    default: {
      heading: '#4A1D96',
      main: '#5850EC',
    },
    orange: {
      heading: '#D03801',
      main: '#FF5A1F',
    },
    yellow: {
      heading: '#E3A008',
      main: '#C27803',
    },
    bunting: {
      heading: '#27303F',
      main: '#364152',
    },
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 100 100">
      <g fill="none">
        <path
          fill={themeStrategy[theme].heading}
          d="M.064 77.93a3.81 3.81 0 013.079-4.442c27.281-4.92 45.484-31.13 40.556-58.426a3.834 3.834 0 013.092-4.44c2.061-.373 4.049 1.012 4.43 3.088 5.68 31.437-15.286 61.63-46.726 67.297-2.08.372-4.062-1-4.431-3.077z"
          transform="rotate(-45 51.408 20.963)"
        />
        <path
          fill={themeStrategy[theme].main}
          d="M59.933 12.039a4.285 4.285 0 00-1.763-2.73L44.75 0l-9.336 13.417c-1.316 1.929-.843 4.561 1.08 5.875a4.201 4.201 0 005.862-1.05l4.511-6.49 6.487 4.499a4.21 4.21 0 005.88-1.059c.675-.955.89-2.082.699-3.153z"
          transform="rotate(-45 51.408 20.963)"
        />
        <path
          fill={themeStrategy[theme].heading}
          d="M37.032 19.54l8.921-12.852 12.85 8.924c-1.405 1.503-3.686 1.846-5.443.636l-6.487-4.499-4.51 6.49c-1.228 1.75-3.488 2.215-5.33 1.301z"
          transform="rotate(-45 51.408 20.963)"
        />
      </g>
    </svg>
  );
};

export const IconInstagram = ({ width = '1em', height = '1em' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 56 56">
    <defs>
      <linearGradient id="instagram-a" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#993DB2" />
        <stop offset="50.833%" stopColor="#D15873" />
        <stop offset="100%" stopColor="#E68330" />
      </linearGradient>
    </defs>
    <path
      fill="url(#instagram-a)"
      d="M28.006 13.64a14.332 14.332 0 00-14.354 14.354 14.332 14.332 0 0014.354 14.354 14.332 14.332 0 0014.355-14.354 14.332 14.332 0 00-14.355-14.355zm0 23.686c-5.134 0-9.332-4.185-9.332-9.332 0-5.147 4.185-9.333 9.332-9.333 5.147 0 9.333 4.186 9.333 9.333s-4.198 9.332-9.333 9.332zm18.29-24.274a3.34 3.34 0 01-3.348 3.348 3.34 3.34 0 01-3.348-3.348 3.348 3.348 0 016.696 0zm9.507 3.398c-.212-4.485-1.237-8.458-4.522-11.73C48.008 1.445 44.035.421 39.55.196c-4.623-.263-18.477-.263-23.1 0-4.472.212-8.445 1.237-11.73 4.51C1.433 7.98.421 11.953.196 16.437c-.263 4.623-.263 18.478 0 23.1.212 4.485 1.237 8.458 4.522 11.731 3.286 3.273 7.246 4.298 11.731 4.523 4.623.262 18.477.262 23.1 0 4.485-.213 8.458-1.237 11.73-4.523 3.274-3.273 4.298-7.246 4.523-11.73.263-4.623.263-18.465 0-23.088zm-5.971 28.047c-.975 2.449-2.861 4.335-5.322 5.322-3.686 1.462-12.431 1.124-16.504 1.124-4.072 0-12.83.325-16.503-1.124-2.449-.974-4.335-2.86-5.322-5.322-1.462-3.685-1.124-12.43-1.124-16.503S4.732 15.164 6.18 11.49c.974-2.448 2.86-4.335 5.322-5.322 3.685-1.461 12.43-1.124 16.503-1.124s12.83-.325 16.504 1.124c2.448.975 4.335 2.861 5.322 5.322 1.461 3.686 1.124 12.431 1.124 16.504 0 4.072.337 12.83-1.124 16.503z"
    />
  </svg>
);

export const IconInformation = ({ width = '1em', height = '1em' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H24V24H0z" />
      <g fill="currentColor" fillRule="nonzero" transform="translate(2 2)">
        <path d="M10 20C4.486 20 0 15.515 0 10 0 4.487 4.486 0 10 0s10 4.486 10 10c0 5.515-4.486 10-10 10zm0-18c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z" />
        <path d="M11 13V9a1 1 0 00-1-1H8v2h1v3H7v2h6v-2h-2z" />
        <circle cx="10" cy="6" r="1.25" />
      </g>
    </g>
  </svg>
);

export const IconLinkedin = ({ width = '1em', height = '1em' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 56 56">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M3 4H51V50H3z" />
      <path
        fill="#3865C2"
        fillRule="nonzero"
        d="M52 0H3.987C1.787 0 0 1.813 0 4.037v47.925C0 54.188 1.788 56 3.987 56H52c2.2 0 4-1.813 4-4.038V4.038C56 1.814 54.2 0 52 0zM16.925 48h-8.3V21.275h8.313V48h-.013zm-4.15-30.375a4.815 4.815 0 01-4.813-4.813c0-2.65 2.15-4.812 4.813-4.812a4.823 4.823 0 014.812 4.813 4.807 4.807 0 01-4.812 4.812zM48.038 48h-8.3V35c0-3.1-.063-7.087-4.313-7.087-4.325 0-4.987 3.375-4.987 6.862V48h-8.3V21.275H30.1v3.65h.112c1.113-2.1 3.826-4.313 7.863-4.313 8.4 0 9.962 5.538 9.962 12.738V48z"
      />
    </g>
  </svg>
);

export const IconPencil = ({ width = '1em', height = '1em' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      transform="translate(1 1)"
    >
      <path d="M15.246.754a2.588 2.588 0 00-3.677.025L1.26 11.089 0 16l4.91-1.259L15.22 4.43a2.587 2.587 0 00.026-3.676z" />
      <path d="M11.275 1.074L14.926 4.725" />
      <path d="M1.259 11.089L4.914 14.737" />
    </g>
  </svg>
);

export const IconTriangle = ({ width = '1em', height = '1em' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 8 12">
    <path fill="currentColor" fillRule="evenodd" d="M137 17L149 17 143 25z" transform="matrix(0 1 1 0 -17 -137)" />
  </svg>
);

export const IconTwitter = ({ width = '1em', height = '1em' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 56 46">
    <path
      fill="#4FA1F2"
      d="M50.244 11.335c.035.497.035.995.035 1.492 0 15.173-11.548 32.655-32.655 32.655C11.122 45.482 5.081 43.6 0 40.33c.924.107 1.812.142 2.772.142A22.985 22.985 0 0017.02 35.57c-5.045-.107-9.274-3.412-10.73-7.96.71.107 1.42.178 2.167.178 1.03 0 2.06-.142 3.02-.391-5.259-1.066-9.203-5.685-9.203-11.264v-.142a11.559 11.559 0 005.188 1.457 11.47 11.47 0 01-5.117-9.559c0-2.132.569-4.086 1.564-5.792a32.623 32.623 0 0023.665 12.01 12.956 12.956 0 01-.285-2.629C27.29 5.152 32.406 0 38.766 0a11.45 11.45 0 018.386 3.624A22.596 22.596 0 0054.437.853a11.446 11.446 0 01-5.046 6.325C51.7 6.929 53.939 6.289 56 5.4a24.671 24.671 0 01-5.756 5.934z"
    />
  </svg>
);

export const IconBgBottom = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="124px" viewBox="0 0 414 124">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#252F3F"
        d="M0 0L414 0 414 124 341.913 92 234.125 124 125.678 92 0 124z"
        opacity="0.9"
        transform="matrix(1 0 0 -1 0 124)"
      />
      <path
        fill="#161E2E"
        d="M0 8L414 8 414 108 341.913 92 210.125 92 85.678 124 0 108z"
        opacity="0.5"
        transform="matrix(1 0 0 -1 0 132)"
      />
      <path
        fill="#161E2E"
        d="M0 0L414 0 414 124 325.913 68 170.125 60 45.678 92 0 124z"
        opacity="0.6"
        transform="matrix(1 0 0 -1 0 124)"
      />
    </g>
  </svg>
);
