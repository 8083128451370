import React from 'react';
import { WrapperStyled, ContentStyled } from './styles';
import logoPng from '../../../../../assets/images/mostfinder.svg';
import { Container } from '../../../globalStyles/utils';

const Header = () => {
  return (
    <WrapperStyled>
      <Container>
        <ContentStyled>
          <img src={logoPng} alt="logo" width={192} height={80} />
        </ContentStyled>
      </Container>
    </WrapperStyled>
  );
};

export default Header;
