import React from 'react';
import { H1Styled, WinnerAttributeStyled, DescriptionStyled } from '../../globalStyles/utils';

const Heading = ({ content, size='large' }) => (
  <>
    <H1Styled size={size}>{content.title}</H1Styled>
    {content.winnerAttribute ? (
      <WinnerAttributeStyled>{content.winnerAttribute.replace('/', ' / ')}</WinnerAttributeStyled>
    ) : null}
    {content.descriptions.map((description, index) => (
      <DescriptionStyled size={content.descriptionSize} key={index} align={content.descriptionAlign}>
        {description}
      </DescriptionStyled>
    ))}
  </>
);

export default Heading;
